import * as React from "react"
import Layout from "../components/layout"
import BackgroundImage from 'gatsby-background-image'
import Img from "gatsby-image"

const VibraOne = ({ data }) => {

    const grayBorder = { borderRight: '3px solid #7f7f7f' }
    const coverImageStack = [
        data.coverImage.childImageSharp.fixed,
        `linear-gradient(0deg, rgba(20, 0, 20, 0.2), rgba(20, 0, 20, 0.2))`
    ].reverse()

    const seo ={
        title: 'Vibra One',
        description: 'Mhm'
    }
    return (
       

        <Layout>
            <div className="hero-area hero-slider-two vibra-one-page">

                <BackgroundImage
                    className="single-hero-slider-one"
                    Tag="section"
                    fluid={coverImageStack}
                    backgroundColor={`#040e18`}
                    alt="vibra one silent office booth"
                >

                    <div className="container">
                        <div className="row" style={{ marginTop: "-130px" }}>
                            <div className="col-lg-12">
                                <div className="hero-text-two text-left">

                                    <h1 className="hero-title--one d-none d-md-block d-lg-block d-xl-block" style={{ fontWeight: 800 }}>VIBRA One</h1>
                                    <h4 className="mt-30 hero-text d-none d-md-block d-lg-block d-xl-block" style={{ color: "#e0d5cc", fontSize: "30px" }}>
                                         The Deal Maker for your Open Office 
                                    </h4> 
                                </div>
                            </div>
                        </div>
                    </div>
                </BackgroundImage>
            </div>
            <div className="featuted-product-wrap section-space--pt_120 vibra-one-page d-block d-md-none d-lg-none d-xl-none">
                <div className="container">
                    <div className="row align-items-center ">
                        <div className="col">
                            <h1 style={{ fontWeight: 800 }}>VIBRA One</h1>
                            <p>
                                Silent booths VIBRA are <br />
                                taking care of the healthy workplace in your company. <br /><br />

                                Whenever you need a quiet place for you to focus<br />
                                or place where you can say your ideas aloud, <br />
                                VIBRA One is here for you.
                                        </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="featuted-product-wrap section-space--pt_120 vibra-one-page">
                <div className="container">
                    <div className="row align-items-center featuted-product-one">
                        <div className="col-lg-6 col-md-6 order-lg-2 order-1">
                            <div className="product-thumbnail">
                               
                                    <Img fluid={data.ceilingImage.childImageSharp.fluid} alt="vibra one office booth design"></Img>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 order-lg-1 order-2">
                            <div className="featured-product-contect">
                                <div className="row">
                                    <div className="col" style={grayBorder}>
                                        <Img fluid={data.lightIcon.childImageSharp.fluid}></Img>
                                    </div>
                                    <div className="col-8">
                                        <h4>Light</h4>
                                        <p><br />Vibra One is lightening your ideas with pleasant LED light (3000K, IP20)</p>

                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: '100px' }}>
                                    <div className="col" style={grayBorder}>
                                        <Img fluid={data.ventilationIcon.childImageSharp.fluid}></Img>
                                    </div>
                                    <div className="col-8">
                                        <h4>Ventilation</h4>
                                        <p><br />Ventilation that will nourish perfect conditions for great focus while keeping the outside noises outside</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="featuted-product-wrap section-space--pt_120 vibra-one-page" style={{ background: '#fbfbfb', paddingBottom: '120px' }}>
                <div className="container">
                    <div className="row align-items-center featuted-product-one">
                        <div className="col-lg-6 col-md-6 order-lg-1 order-1">
                            <div className="product-thumbnail">
                                    <Img fluid={data.tableImage.childImageSharp.fluid} alt="vibra one office booth table"> </Img>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 order-lg-2 order-2">
                            <div className="featured-product-contect">
                                <div className="row">
                                    <div className="col" style={grayBorder}>
                                        <Img fluid={data.powerIcon.childImageSharp.fluid}></Img>
                                    </div>
                                    <div className="col-8">
                                        <h4>Power</h4>
                                        <p><br />We use motion sensors to control the usage of power (outlet 100-240 VAC)</p>

                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: '100px' }}>
                                    <div className="col" style={grayBorder}>
                                        <Img fluid={data.insolationIcon.childImageSharp.fluid}></Img>
                                    </div>
                                    <div className="col-8">
                                        <h4>Sound Insulation</h4>
                                        <p><br />Soundproof sandwich wall made with laminated MDF, mineral wool and sound-absorbing felt, connected on wooden board.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="featuted-product-wrap section-space--pt_120 vibra-one-page">
                <div className="container">
                    <div className="row align-items-center featuted-product-one">
                        <div className="col-lg-6 col-md-6 order-lg-2 order-1">
                            <div className="product-thumbnail">
                                    <Img fluid={data.dimensionsImage.childImageSharp.fluid} alt="vibra one office booth dimenisions"> </Img>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 order-lg-1 order-2">
                            <div className="featured-product-contect">
                                <h4 style={{ borderLeft: '3px solid #7f7f7f', padding: '10px' }}>Product <br /> dimensions</h4>
                                <br />
                                <h5>Exterior measurements</h5>
                                <br />
                                <p>H 2210 mm x W 1040 mm x D 940 mm<br />
                                    H 87.00" x W 40.94" x D 37.00"</p>
                                <br />
                                <h5>Interior measurements</h5>
                                <br />
                                <p>H 2050 mm x W 896 mm x D 880 mm <br />
                                    H 80.70" x W 35.27" x D 34.64"</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
};

export const query = graphql`
  query {
    coverImage: file(
      relativePath: { eq: "vibra-one/cover.jpg" }
    ) {
      childImageSharp {
        fixed(width: 1920, height: 960) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ceilingImage: file(relativePath: { eq: "vibra-one/vibra-ceiling.jpg" }){
        childImageSharp {
            fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    tableImage: file(relativePath: { eq: "vibra-one/vibra-table.jpg" }){
        childImageSharp {
            fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    dimensionsImage: file(relativePath: { eq: "vibra-one/vibra-dimensions.png" }){
        childImageSharp {
            fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    lightIcon: file(relativePath: { eq: "vibra-one/lightIcon.png" }){
        childImageSharp {
            fluid(maxWidth: 250) {
                ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    ventilationIcon: file(relativePath: { eq: "vibra-one/ventilationIcon.png" }){
        childImageSharp {
            fluid(maxWidth: 250) {
                ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    powerIcon: file(relativePath: { eq: "vibra-one/powerIcon.png" }){
        childImageSharp {
            fluid(maxWidth: 250) {
                ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    insolationIcon: file(relativePath: { eq: "vibra-one/insolationIcon.png" }){
        childImageSharp {
            fluid(maxWidth: 250) {
                ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
  }`


export default VibraOne